import { getLangFromUrl, useTranslations } from "src/i18n/utils";
import { cn } from "../../lib/utils";
import Marquee from "../base/Marquee";

const url = new URL(window.location.href);
const lang = getLangFromUrl(url);
const t = useTranslations(lang);

const reviews = t("reviews");

const ReviewCard = ({
  img,
  name,
  username,
  body,
  index,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
  index: number;
}) => {
  const bgClasses = ["bg-[#bbf7d0]", "bg-[#bcd6ff]", "bg-[#fef9c3]"];
  const bgClassesDark = ["bg-[#38b36b ]", "bg-[#3977f9]", "bg-[#ca8a04]"];
  const bgColor = bgClasses[index % bgClasses.length];
  const bgColorDark = bgClassesDark[index % bgClassesDark.length];

  return (
    <figure
      className={cn(
        "relative w-52 md:w-64 cursor-pointer overflow-hidden rounded-xl p-4",
        `${bgColor} bg-opacity-60 hover:bg-opacity-80`,
        `dark:${bgColorDark} dark:bg-opacity-30 dark:hover:bg-opacity-40`,
        "border-gray-200 dark:border-gray-700",
        `border-[1px]`
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption
            className={`text-sm font-medium text-gray-900 dark:text-gray-200`}
          >
            {name}
          </figcaption>
          <p className={`text-xs font-medium text-gray-500 dark:text-gray-400`}>
            {username}
          </p>
        </div>
      </div>
      <blockquote className={`mt-2 text-sm text-gray-800 dark:text-white/80`}>
        {body}
      </blockquote>
      <div className="absolute bottom-1 right-1 w-8 h-8 flex items-center justify-center rounded-full shadow-sm">
        <i
          className="iconify text-xl text-muted-500 dark:text-muted-300 p-0.5"
          data-icon="fa6-solid:quote-left"
        ></i>
      </div>
    </figure>
  );
};

export const MarqueeDemo = () => {
  return (
    <div
      id="testimonials"
      x-intersect="activeStep = 'spy-4'"
      className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden bg-slate-100 dark:bg-muted-1000 py-10"
    >
      <Marquee pauseOnHover className="[--duration:25s]">
        {reviews.map((review, index) => (
          <ReviewCard
            key={`${review.username}-${index}`}
            {...review}
            index={index}
          />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/6 md:w-1/4 bg-gradient-to-r from-slate-100 dark:from-muted-1000"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/6 md:w-1/4 bg-gradient-to-l from-slate-100 dark:from-muted-1000"></div>
    </div>
  );
};
